import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  //test
  //apiUrl = "http://r7-api-legacy.lacaisse.ma:91/"

  //prod
  apiUrl = "https://api-legacy.lacaisse.ma/"

  constructor(private http: HttpClient) { }

  login(username, password): Observable<any> {
    const body = new HttpParams()
      .set('login', username)
      .set('password', password);
  
    return this.http.post(this.apiUrl+'Commandes/connexionCaisse.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  getUsers(tokenApi, caisseId): Observable<any>{
    const body = new HttpParams()
    .set('token_api', tokenApi)
    .set('id_caisse', caisseId);

    return this.http.post(this.apiUrl+'Commandes/getUser.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  getSuppliers(tokenApi, caisseId): Observable<any>{
    const body = new HttpParams()
    .set('token_api', tokenApi)
    .set('id_caisse', caisseId);

    return this.http.post(this.apiUrl+'Commandes/getFournisseurs.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  getProducts(tokenApi, caisseId, supplierId): Observable<any>{
    const body = new HttpParams()
    .set('token_api', tokenApi)
    .set('id_caisse', caisseId)
    .set('id_fournisseur', supplierId)

    return this.http.post(this.apiUrl+'Commandes/getProducts2.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  getCaisses(tokenApi, caisseId): Observable<any>{
    const body = new HttpParams()
    .set('token_api', tokenApi)
    .set('id_caisse', caisseId);

    return this.http.post(this.apiUrl+'Commandes/getCaisses.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  createPurchaseOrder(senderBox, boxName, userId, SupplierId, supplierPhone,supplierName, storeName, boxIntended, products){
    const body = new HttpParams()
    .set('sender_box', senderBox)
    .set('box_name', boxName)
    .set('user_id', userId)
    .set('supplier_id', SupplierId)
    .set('supplier_phone', supplierPhone)
    .set('supplier_name', supplierName)
    .set('store_name', storeName)
    .set('box_intended', boxIntended)
    .set('products', products)

    return this.http.post(this.apiUrl+'Commandes/postCommandes.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  getOrdersByUser(userId, boxId): Observable<any>{
    const body = new HttpParams()
    .set('user_id', userId)
    .set('box_id', boxId)

    return this.http.post(this.apiUrl+'Commandes/getPurchaseOrders.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  getOrderDetailsByOrderId(orderId, box_id): Observable<any>{
    const body = new HttpParams()
    .set('order_id', orderId)
    .set('box_id', box_id)
    return this.http.post(this.apiUrl+'Commandes/getOrderDetailsByOrderId.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  addQuantityReceived(orderId, products, note){
    const body = new HttpParams()
    .set('order_id', orderId)
    .set('note', note)
    .set('products', products)
    return this.http.post(this.apiUrl+'Commandes/postQuantityReceived.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  acceptAnOrder(orderId, box_id,products, note){
    const body = new HttpParams()
    .set('order_id', orderId)
    .set('box_id', box_id)
    .set('note', note)
    .set('products', products)
    return this.http.post(this.apiUrl+'Commandes/postAcceptAnOrder.php',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }




}

