import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PopupAddProductComponent } from '../popup-add-product/popup-add-product.component';
import { BasketService } from 'src/app/services/basket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-create-purchase-order',
  templateUrl: './create-purchase-order.component.html',
  styleUrls: ['./create-purchase-order.component.scss']
})
export class CreatePurchaseOrderComponent implements OnInit {

  downloadProductsPending = false
  
  title = "nouvelle commande"

  note = ""

  //Animations variables
  listSteps
  listStepsFinished
  listButtons


  heightOfTheBasket = (window.innerHeight - 286) + 'px';

  newOrderForm = new FormGroup({
    supplier : new FormControl( "" , Validators.required),
    product : new FormControl( "" ,Validators.required),
    caisse: new FormControl( "" ,Validators.required),
    note: new FormControl("")
  })

  selectedSupplierId = ""
  selectedSupplierPhone = ""

  step = 1
  stepsFinished = [
    false,
    false,
    false,
    false
  ]

  listSuppliers = []
  listProducts = []
  listCaisses = []

  filteredSuppliers: Observable<string[]>;

  tokenApi = window.localStorage.getItem('token');
  caisseId = window.localStorage.getItem('caisse');
  caisseName = window.localStorage.getItem('name');
  loggedInUser = JSON.parse(window.localStorage.getItem('LoggedInUser'));
  user_id = this.loggedInUser.id_user;


  


  constructor(
    private http: HttpService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private basketSerive: BasketService,
    private element: ElementRef,
    private router: Router
  ) { }

  ngOnInit(): void { 
    this.listStepsFinished 

    this.basketSerive.initializeLocalStorageWithEmptyBasket();
    this.basketSerive.inializeBasket();
    this.http.getSuppliers(this.tokenApi, this.caisseId).subscribe( listOfSuppliers => {
      this.listSuppliers = listOfSuppliers
    })

    this.http.getProducts(this.tokenApi, this.caisseId, '-1').subscribe( listOfProducts => {
      this.listProducts = listOfProducts
    })

    this.http.getCaisses(this.tokenApi, this.caisseId).subscribe( listOfCaisses => {
      this.listCaisses = listOfCaisses
    })

    this.filteredSuppliers = this.newOrderForm.get('supplier').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.listSuppliers.filter(supplier => supplier.nom_fournisseur.toLowerCase().includes(filterValue));
  }

  async next(){
    let supplierName = this.newOrderForm.get('supplier').value
    let products = window.localStorage.getItem('selectedProducts');

    if((!supplierName || supplierName == "") && this.step == 1 ){
      this.snackBar.open("Veuillez choisir un fournisseur !","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      });
    }else if((!products || products == "[]") && this.step == 2){
      this.snackBar.open("Veuillez choisir les produits désirés !","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      });
    }else{
      this.stepsFinished[this.step] = true
      this.step = this.step + 1
    }
  }

  async previous(){
      this.step = this.step - 1
      this.stepsFinished[this.step] = false
  }

  validate(){
    let supplierName = this.newOrderForm.get('supplier').value
    let products = window.localStorage.getItem('selectedProducts');
    let boxIntended = this.newOrderForm.get('caisse').value.id_caisse;
    let storeName = this.newOrderForm.get('caisse').value.nom_boutique;
    let note = this.newOrderForm.get('note').value

    
    if(!boxIntended && this.step == 3){
      this.snackBar.open("Veuillez choisir une boutique !","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      });
    }else{
      
      this.http.createPurchaseOrder(this.caisseId, this.caisseName, this.user_id, this.selectedSupplierId, this.selectedSupplierPhone,supplierName, storeName, boxIntended, products, note).subscribe(result => {
        this.basketSerive.basket.items = []
        setTimeout(()=>{
          this.router.navigate(['/order-details', result])
        }, 1000)
      })
      
      this.basketSerive.basket = {
                                    items: [],
                                  }
                                  
    }  
    if(this.step == 3){
      this.stepsFinished[this.step] = true
      this.step = this.step + 1
    }
    
  }

  openAddProductPopup(){
    this.dialog.open(PopupAddProductComponent, 
      {
      width: '90vw',
      maxWidth: '90vw',
      height: '90%',
      panelClass: 'custom-modal',
      disableClose: true,
      data: this.listProducts,
    });
  }

  add(index){
    this.basketSerive.basket.items[index].count++
    this.basketSerive.updateBasketStoredInLocalStorage()
  }

  remove(index){
    if(this.basketSerive.basket.items[index].count > 1){
      this.basketSerive.basket.items[index].count--
    }
    this.basketSerive.updateBasketStoredInLocalStorage()
  }

  removeFromBasket(index){
    this.basketSerive.removeItem(index)
  }

  getSelectedSupplier(option){
    this.selectedSupplierId = option.id_fournisseur
    this.selectedSupplierPhone = option.tel_fournisseur
  }

  onCheckboxChange(event: any) {
    let supplierName = this.newOrderForm.get('supplier').value
    if((!supplierName || supplierName == "")){
      this.snackBar.open("Veuillez choisir un fournisseur !","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      });
      event.target.checked = false
    }else{
      this.downloadProductsPending = true
      if (event.target.checked) {
        this.http.getProducts(this.tokenApi, this.caisseId, this.selectedSupplierId).subscribe( listOfProducts => {
          this.listProducts = listOfProducts
          this.downloadProductsPending = false
        })
      } else {
        this.http.getProducts(this.tokenApi, this.caisseId, '-1').subscribe( listOfProducts => {
          this.listProducts = listOfProducts
          this.downloadProductsPending = false
        })
      }
    }
  }
  
  getTotal(){
    let total = 0
    if(this.basketSerive.basket.items.length > 0){
      this.basketSerive.basket.items.forEach( item => {
        total = total + (parseFloat(item.prix) + (parseFloat(item.prix) * parseFloat(item.tva) / 100)) * item.count
      })
    }
    return total
  }

  getPriceTtc(prix, tva){
    console.log(parseFloat(prix))
    console.log((parseFloat(prix) * parseFloat(tva) / 100))
    return parseFloat(prix) + (parseFloat(prix) * parseFloat(tva) / 100)
  }

}
