<section
    class="section_order_details"
    [ngClass]="'animate__animated animate__slideInRight duration-0-3'"
>
    <app-header [title]='title'></app-header>

    <!-- RECEIVER -->
    <div *ngIf="orderDetails && orderDetails.sender == 1" class="details-cards">
        <div class="section_order_details-details">
            <div class="section_order_details-details-img">
                <img src="../../../assets/images/order-details.png" alt="">
            </div>

            <div class="section_order_details-details-progress">
                <div class="section_order_details-details-progress-line_side left"></div>
                <div class="section_order_details-details-progress-box">
                    <mat-icon class="material-symbols-outlined" *ngIf="orderDetails.status >= 0">
                        done
                    </mat-icon>
                </div>
                <div class="section_order_details-details-progress-line_center"></div>
                <div class="section_order_details-details-progress-box">
                    <mat-icon class="material-symbols-outlined" *ngIf="orderDetails.status >= 1">
                        done
                    </mat-icon>
                </div>
                <div class="section_order_details-details-progress-line_center"></div>
                <div class="section_order_details-details-progress-box">
                    <mat-icon class="material-symbols-outlined" *ngIf="orderDetails.status >= 2">
                        done
                    </mat-icon>
                </div>
                <div class="section_order_details-details-progress-line_side right"></div>
            </div>
            
            <div class="section_order_details-details-stepper">
                <div class="section_order_details-details-stepper-line_side left"></div>
                <div class="section_order_details-details-stepper-box"></div>
                <div class="section_order_details-details-stepper-line_center"></div>
                <div class="section_order_details-details-stepper-box"></div>
                <div class="section_order_details-details-stepper-line_center"></div>
                <div class="section_order_details-details-stepper-box"></div>
                <div class="section_order_details-details-stepper-line_side right"></div>
            </div>

            <div class="section_order_details-details-status">
                <div class="section_order_details-details-status-line_side left"></div>
                <div class="section_order_details-details-status-box">En attente de validation</div>
                <div class="section_order_details-details-status-line_center"></div>
                <div class="section_order_details-details-status-box">En cours de livraison</div>
                <div class="section_order_details-details-status-line_center"></div>
                <div class="section_order_details-details-status-box">Commande livrée</div>
                <div class="section_order_details-details-status-line_side right"></div>
            </div>

            <div class="section_order_details-details-print btn" (click)="printOrderDetails()">
                <img src="../../../assets/images/pdf_.png" alt="">
            </div>

            <div class="section_order_details-details-print_excel btn" (click)="printOrderDetailsExcel()">
                <img src="../../../assets/images/excel_.png" alt="">
            </div>

        </div>

        <div class="section_order_details-order_delivered btn" *ngIf="orderDetails.status == 1" (click)="myOrderIsDelivered()">
            Ma commande est livrée
        </div>

        <div class="section_order_details-order_ticket">

            <div class="section_order_details-order_ticket-title">Ma commande</div>
            <div class="section_order_details-order_ticket-first_line">
                <div class="section_order_details-order_ticket-first_line-title">Boutique : </div>
                <div class="section_order_details-order_ticket-first_line-value"> {{ orderDetails.store_name }}</div>
            </div>
            <div class="section_order_details-order_ticket-products"
                *ngFor="let product of orderDetails.products; index as i"
            >
                <div class="section_order_details-order_ticket-products-products_details">
                    <div class="section_order_details-order_ticket-products-products_details-img">
                        <img [src]="product.imageUrl" alt="">
                    </div>
                    <div class="section_order_details-order_ticket-products-products_details-texts">
                        <div class="section_order_details-order_ticket-products-products_details-texts-category">
                            {{ product.designation_cat }} : <span>{{ product.designation }}</span>
                        </div>
                        <div class="section_order_details-order_ticket-products-products_details-texts-quantity"
                        >
                            - Quantité demandée :  {{ product.count }} 
                        </div>
                        <div class="section_order_details-order_ticket-products-products_details-texts-quantity"
                            *ngIf="product.sended != null"
                        >
                            - Quantité envoyée :  {{ product.sended }} 
                        </div>
                        <div class="section_order_details-order_ticket-products-products_details-texts-quantity"
                            *ngIf="product.sended != null && product.received != null"
                        >
                            - Quantité reçue :  {{ product.received }} 
                        </div>
                    </div>
                </div>
                <hr 
                    class="section_order_details-order_ticket-products-products_divider"
                >
            </div>
            <div class="section_order_details-order_ticket-total">Total : <span> {{ orderDetails.total_ttc | number : '1.2-2' }} Dhs</span></div>
        </div>
    </div>

    <!-- SENDER -->
    <div *ngIf="orderDetails && orderDetails.sender == 0">
        <div class="section_order_details-details_sender">
            <div class="section_order_details-details-img">
                <img src="../../../assets/images/order-details.png" alt="">
            </div>

            <div class="section_order_details-details_sender-progress">
                <div class="section_order_details-details_sender-progress-line_side left"></div>
                <div class="section_order_details-details_sender-progress-box">
                    <mat-icon class="material-symbols-outlined" *ngIf="orderDetails.status >= 0">
                        done
                    </mat-icon>
                </div>
                <div class="section_order_details-details_sender-progress-line_center"></div>
                <div class="section_order_details-details_sender-progress-box">
                    <mat-icon class="material-symbols-outlined" *ngIf="orderDetails.status >= 2">
                        done
                    </mat-icon>
                </div>
                <div class="section_order_details-details_sender-progress-line_side right"></div>
            </div>
            
            <div class="section_order_details-details_sender-stepper">
                <div class="section_order_details-details_sender-stepper-line_side left"></div>
                <div class="section_order_details-details_sender-stepper-box"></div>
                <div class="section_order_details-details_sender-stepper-line_center"></div>
                <div class="section_order_details-details_sender-stepper-box"></div>
                <div class="section_order_details-details_sender-stepper-line_side right"></div>
            </div>

            <div class="section_order_details-details_sender-status">
                <div class="section_order_details-details_sender-status-line_side left"></div>
                <div class="section_order_details-details_sender-status-box">Commande à valider</div>
                <div class="section_order_details-details_sender-status-line_center"></div>
                <div class="section_order_details-details_sender-status-box">Commande livrée</div>
                <div class="section_order_details-details_sender-status-line_side right"></div>
            </div>

            <div class="section_order_details-details-print btn" (click)="printOrderDetails()">
                <img src="../../../assets/images/pdf_.png" alt="">
            </div>

            <div class="section_order_details-details-print_excel btn" (click)="printOrderDetailsExcel()">
                <img src="../../../assets/images/excel_.png" alt="">
            </div>

        </div>

        <div class="section_order_details-order_delivered btn" *ngIf="orderDetails.status == 0" (click)="acceptTheOrder()">
            Accepter la commande
        </div>

        <div class="section_order_details-order_ticket">

            <div class="section_order_details-order_ticket-title">Commande</div>
            <div class="section_order_details-order_ticket-first_line">
                <div class="section_order_details-order_ticket-first_line-title">Client : </div>
                <div class="section_order_details-order_ticket-first_line-value"> {{ orderDetails.box_name }}</div>
            </div>
            <div class="section_order_details-order_ticket-products"
                *ngFor="let product of orderDetails.products; index as i"
            >
                <div class="section_order_details-order_ticket-products-products_details">
                    <div class="section_order_details-order_ticket-products-products_details-img">
                        <img [src]="product.imageUrl" alt="">
                    </div>
                    <div class="section_order_details-order_ticket-products-products_details-texts">
                        <div class="section_order_details-order_ticket-products-products_details-texts-category">
                            {{ product.designation_cat }} : <span>{{ product.designation }}</span>
                        </div>
                        <div class="section_order_details-order_ticket-products-products_details-texts-quantity"
                        >
                            - Quantité demandée :  {{ product.count }} unités
                        </div>
                        <div class="section_order_details-order_ticket-products-products_details-texts-quantity"
                            *ngIf="product.sended != null"
                        >
                            - Quantité envoyée :  {{ product.sended }} unités
                        </div>
                        <div class="section_order_details-order_ticket-products-products_details-texts-quantity"
                            *ngIf="product.sended != null && product.received != null"
                        >
                            - Quantité reçue :  {{ product.received }} unités
                        </div>
                    </div>
                </div>
                <hr 
                    class="section_order_details-order_ticket-products-products_divider"
                >
            </div>

            <div class="section_order_details-order_ticket-total">Total : <span> {{ orderDetails.total_ttc | number : '1.2-2' }} Dhs</span></div>

        </div>
    </div>
    
    

</section>