import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { BasketService } from '../../services/basket.service'

@Component({
  selector: 'app-popup-add-product',
  templateUrl: './popup-add-product.component.html',
  styleUrls: ['./popup-add-product.component.scss']
})
export class PopupAddProductComponent implements OnInit {

  listOfProducts
  filteredCategories

  numberOfSelectedProduct: any[] = []

  searchField = ''

  

  constructor(
      @Inject(MAT_DIALOG_DATA) public products: any, 
      private basketSerive: BasketService, 
      private dialog: MatDialog,
      ) {
        console.log(products)
       }

  ngOnInit(): void {
    this.listOfProducts = this.products

    if(this.listOfProducts[0].family_name){
      for (let index = 0; index < this.listOfProducts.length; index++) {
        for(let j = 0; j < this.listOfProducts[index].categories.length; j++){
          this.listOfProducts[index].categories[j].showProducts = false
          for(let k = 0; k < this.listOfProducts[index].categories[j].products.length; k++){
            this.listOfProducts[index].categories[j].products[k].count = 0
          }
        }
      }
    }else{
      for (let index = 0; index < this.listOfProducts.length; index++) {
        this.listOfProducts[index].showProducts = false
        for ( let j = 0; j < this.listOfProducts[index].products.length; j++ ){
          this.listOfProducts[index].products[j].count = 0
        }
      }
    }
    
  }

  filterCategories() {
    const searchTerm = this.searchField.toLowerCase().trim();
  
    if (searchTerm == '') {
      // Reset all categories and set 'showProducts' to false for both families and categories
      this.listOfProducts = this.products.map(item => {
        if (item.categories) {
          // If families exist, map over categories in each family
          return {
            ...item,
            categories: item.categories.map(category => ({
              ...category,
              showProducts: false // Reset categories within families
            }))
          };
        } else {
          // If there are no families, reset the categories directly
          return {
            ...item,
            showProducts: false
          };
        }
      });
    } else {
      // If search term is not empty
      this.listOfProducts = this.products
        .map(item => {
          if (item.categories) {
            // If there are families, map over categories in each family
            const filteredCategories = item.categories.map(category => {
              const filteredProducts = category.products.filter(product =>
                product.designation.toLowerCase().includes(searchTerm)
              );
              return {
                ...category,
                products: filteredProducts,
                showProducts: filteredProducts.length > 0
              };
            }).filter(category => category.products.length > 0);
  
            // Return the family with the filtered categories
            return {
              ...item,
              categories: filteredCategories
            };
          } else {
            // If no families, work directly with categories (same structure as before)
            const filteredProducts = item.products.filter(product =>
              product.designation.toLowerCase().includes(searchTerm)
            );
            
            return {
              ...item,
              products: filteredProducts,
              showProducts: filteredProducts.length > 0
            };
          }
        })
        // Filter out families or categories that have no matching products
        .filter(item => {
          if (item.categories) {
            return item.categories.length > 0;
          } else {
            return item.products.length > 0;
          }
        });
    }
  }

  highlightText(text: string): string {
    const searchTerm = this.searchField.toLowerCase().trim();
    
    if (!searchTerm) {
      return text; // Return the original text if search field is empty
    }

    // Create a regular expression to find the search term (case-insensitive)
    const regex = new RegExp(`(${searchTerm})`, 'gi');

    // Replace the matched term with highlighted HTML
    return text.replace(regex, `<span class="highlight">$1</span>`)
  }
  
 

  numberOfSelectedProductsForEachCategory(){
    if(this.listOfProducts[0].family_name){
      for(let i = 0; i < this.listOfProducts.length; i++){
        this.numberOfSelectedProduct[i] = [];
        for (let j = 0; j < this.listOfProducts[i].categories.length; j++) {
          let count = 0
          for (let k = 0; k < this.listOfProducts[i].categories[j].products.length; k++) {
            if(this.listOfProducts[i].categories[j].products[k].count > 0) count++
          }
          this.numberOfSelectedProduct[i][j] = count
        }
      }
    }else{
      for (let i = 0; i < this.listOfProducts.length; i++) {
        let count = 0
        for (let j = 0; j < this.listOfProducts[i].products.length; j++) {
          if(this.listOfProducts[i].products[j].count > 0) count++
        }
        this.numberOfSelectedProduct[i]=count
      }
    }
    console.log(this.numberOfSelectedProduct)
  }

  showMore(i, j?){
    /*
    for (let index = 0; index < this.listOfProducts.length; index++) {
      this.listOfProducts[index].showProducts = false
    }
    */
   if(this.listOfProducts[0].family_name){
    console.log(this.listOfProducts[i])
    this.listOfProducts[i].categories[j].showProducts = !this.listOfProducts[i].categories[j].showProducts
   }else{
    this.listOfProducts[i].showProducts = !this.listOfProducts[i].showProducts
   }
    
  }

  showLess(i, j?){
    
    if(this.listOfProducts[0].family_name){
      this.listOfProducts[i].categories[j].showProducts = !this.listOfProducts[i].categories[j].showProducts
     }else{
      this.listOfProducts[i].showProducts = !this.listOfProducts[i].showProducts
     }
  }


  /*  
        this.listOfProducts[index] = {
          "id": this.listOfProducts[index].id,
          "count":this.listOfProducts[index].count,
          "designation": this.listOfProducts[index].designation,
          "valid": false,
          "edit": true
        }
    */

  /*
  async addToBasket(index){
    await this.basketSerive.addItem(this.filteredProductsList[index])
    await this.dialog.closeAll()
    await this.filteredProductsList.forEach(product => {
      product.count = 1  
    });
  }
  */

  async addToBasket(){
    this.searchField = await ''
    this.filterCategories()
    if(this.listOfProducts[0].family_name){
      for (let i = 0; i < this.listOfProducts.length; i++) {
        for (let j = 0; j < this.listOfProducts[i].categories.length; j++){
          for ( let k = 0; k < this.listOfProducts[i].categories[j].products.length; k++ ){
            if(this.listOfProducts[i].categories[j].products[k].count != 0 && this.listOfProducts[i].categories[j].products[k].count != null)
             this.basketSerive.addItem(this.listOfProducts[i].categories[j].products[k])  
          }
        }    
      }
      this.dialog.closeAll()
    }else{
      for (let i = 0; i < this.listOfProducts.length; i++) {
        for ( let j = 0; j < this.listOfProducts[i].products.length; j++ ){
          if(this.listOfProducts[i].products[j].count != 0 && this.listOfProducts[i].products[j].count != null)
           this.basketSerive.addItem(this.listOfProducts[i].products[j])  
        }
      }
      this.dialog.closeAll()
    }
    
  }

}

