import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-popup-accept-an-order',
  templateUrl: './popup-accept-an-order.component.html',
  styleUrls: ['./popup-accept-an-order.component.scss']
})
export class PopupAcceptAnOrderComponent implements OnInit {

  productsUpdated = []
  listOfProducts
  note = ""
  caisseId = window.localStorage.getItem('caisse');

  pending: boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) public orderDetails: any,
                      private dialog: MatDialog, 
                      private http: HttpService,
                      private snackBar: MatSnackBar,
                      ) { }

  ngOnInit(): void {
    this.listOfProducts = this.orderDetails.products
    for (let index = 0; index < this.listOfProducts.length; index++) {
        if( this.listOfProducts[index].sended == null && this.listOfProducts[index].received == null ){
      
          this.productsUpdated[index] = {
            "id": this.listOfProducts[index].id,
            "count":this.listOfProducts[index].count,
            "designation": this.listOfProducts[index].designation,
            "valid": false,
            "edit": true
          }
        }else if(this.listOfProducts[index].sended != null && this.listOfProducts[index].received == null
                ){
           
                  this.productsUpdated[index] = {
                    "id": this.listOfProducts[index].id,
                    "count":this.listOfProducts[index].sended,
                    "designation": this.listOfProducts[index].designation,
                    "valid": false,
                    "edit": true
                  }
        }
        else{
            this.productsUpdated[index] = {
            "id": this.listOfProducts[index].id,
            "count":this.listOfProducts[index].received,
            "designation": this.listOfProducts[index].designation,
            "valid": false,
            "edit": true
          }
        }
        
    }
  }

  validate(index){
    if(this.productsUpdated[index].count == null){
      this.productsUpdated[index].count = 0
    }
    this.productsUpdated[index].valid = false
    this.productsUpdated[index].edit = true
  }

  edit(index){
    this.productsUpdated[index].edit = false
    this.productsUpdated[index].valid = true
    this.productsUpdated.forEach( (product, i) => {
      if(index != i) {
        product.edit = true
        product.valid = false
      }
    });
    //document.getElementById(index).focus()
  }


  addQuantitySended(){
    let aProductThatMustBeQuantified = this.productsUpdated.filter( product => product.valid == true )

    if(aProductThatMustBeQuantified.length != 0 ){
      this.snackBar.open("Veuillez valider la quantité du produit "+ aProductThatMustBeQuantified[0].designation +"!","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      })
    }else{  
      if(!this.pending){
        this.pending = true
        let orderId = this.orderDetails.orderId
        let products = JSON.stringify(this.productsUpdated)
        this.http.acceptAnOrder(orderId, this.caisseId,products, this.note).subscribe(result => {
          window.location.reload()
        })
      }
    }
  }






  
}