<section class="order_is_delivered">
    <div class="order_is_delivered-notice" >NOTICE</div>
    <div class="order_is_delivered-message">Veuillez vérifier les quantités demandées par le client et les corriger en cas de divergence entre les quantités commandées et celles que vous avez en stock.</div>
    <div class="order_is_delivered-table">
        <div class="order_is_delivered-table-titles">
            <div class="order_is_delivered-table-titles-products">Produits</div>
            <div class="order_is_delivered-table-titles-count">Nbr</div>
            <div class="order_is_delivered-table-titles-actions">Actions</div>
        </div>
        <div 
            class="order_is_delivered-table-lines"
            *ngFor="let product of listOfProducts; index as i"
        >
            <div class="order_is_delivered-table-lines-products">{{ product.designation }}</div>
            <div 
                class="order_is_delivered-table-lines-count"
            >
                <input type="number" [id]="i" [(ngModel)]="productsUpdated[i].count" 
                    [readonly]="productsUpdated[i].edit" 
                    [ngClass]="{'readonly': productsUpdated[i].edit}" 
                >
            </div>
            <div class="order_is_delivered-table-lines-actions">
                <mat-icon *ngIf="productsUpdated[i].valid" class="material-symbols-outlined done" (click)="validate(i)">
                    done
                </mat-icon>
                <mat-icon *ngIf="productsUpdated[i].edit" class="material-symbols-outlined edit" (click)="edit(i)">
                    edit
                </mat-icon>
            </div>
        </div>
    </div>
    <div class="order_is_delivered-note">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Note</mat-label>
          <textarea [(ngModel)]="note" matInput #message maxlength="256" placeholder="Ex. I need help with..."></textarea>
          <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
        </mat-form-field>
    </div>
    <div class="order_is_delivered-submit btn" (click)="addQuantitySended()">
        <span *ngIf="!pending">VALIDER</span>
        <img *ngIf="pending" src="../../../assets/images/loader.svg" alt="">
    </div>
</section>