
<section class="order_is_delivered">
    <div class="order_is_delivered-notice" >Notice</div>
    <div class="order_is_delivered-message">Veuillez sélectionner les produits souhaités.</div>

    <div class="order_is_delivered-search">
        <input 
            type="text"
            placeholder="Trouver un produit ..."
            aria-label="Number"
            matInput
            [(ngModel)]="searchField"
            (input)="filterCategories()"
        >
    </div>

    <div class="order_is_delivered-not_found" *ngIf="listOfProducts.length == 0">
        Aucun résultat trouvé"
    </div>
    
    <div class="order_is_delivered-table">
        <div class="order_is_delivered-table-titles" *ngIf="listOfProducts.length != 0">
            <div class="order_is_delivered-table-titles-products">Produits</div>
            <div class="order_is_delivered-table-titles-count">Nbr</div>
        </div>
        <!---->
        <div
            *ngIf="listOfProducts[0] && listOfProducts[0].family_name"
        >
            <div        
                class="order_is_delivered-table-lines"
                *ngFor="let family of listOfProducts; index as i"
            >
                <div 
                    class="order_is_delivered-table-lines-categorie"
                    style="
                            background-color: #a5a5a5;
                            text-align: center;
                            font-size: 15px;
                            padding-left: 15px;
                        "   
                    >
                    <div 
                        class="order_is_delivered-table-lines-categorie-details"
                        
                    >
                        <div>{{ family.family_name }}</div>
                    </div>
                    <!--
                    <div class="order_is_delivered-table-lines-categorie-actions">
                        <mat-icon class="material-symbols-outlined" *ngIf="!categorie.showProducts" (click)="showMore(i)">expand_more</mat-icon>
                        <mat-icon class="material-symbols-outlined" *ngIf="categorie.showProducts"  (click)="showLess(i)">expand_less</mat-icon>
                    </div>
                    -->
                </div>
                <div
                    *ngFor="let categorie of family.categories; index as j"
                >
                    <div class="order_is_delivered-table-lines-categorie">
                        <div class="order_is_delivered-table-lines-categorie-details">
                            <div class="order_is_delivered-table-lines-categorie-details-img" 
                                [style.background-image]="'url(' + categorie.image_cat + ')'"
                                *ngIf="!categorie.showProducts"
                            >
                            </div>
                            <div>{{ categorie.name }}</div>
                        </div>
                        <div class="order_is_delivered-table-lines-categorie-actions">
                            <div class="order_is_delivered-table-lines-categorie-actions-count">
                                <span *ngIf="!numberOfSelectedProduct[i] || (numberOfSelectedProduct[i] && !(numberOfSelectedProduct[i][j] > 0))">0</span>
                                <span *ngIf="numberOfSelectedProduct[i] && numberOfSelectedProduct[i][j] > 0">{{ numberOfSelectedProduct[i][j] }}</span>/{{ categorie.products.length }}
                            </div>
                            <mat-icon class="material-symbols-outlined" *ngIf="!categorie.showProducts" (click)="showMore(i, j)">expand_more</mat-icon>
                            <mat-icon class="material-symbols-outlined" *ngIf="categorie.showProducts"  (click)="showLess(i, j)">expand_less</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="categorie.showProducts">
                        <div class="order_is_delivered-table-lines-products" 
                            *ngFor="let product of categorie.products; index as k" 
                        >
                            <div class="order_is_delivered-table-lines-product">
                                <div class="order_is_delivered-table-lines-product-img" [style.background-image]="'url(' + product.imageUrl + ')'"></div>
                                <span [innerHTML]="highlightText(product.designation)"></span>
                            </div>
                            <div 
                                class="order_is_delivered-table-lines-count"
                            >
                            <input type="number" (input)="numberOfSelectedProductsForEachCategory()" [(ngModel)]="listOfProducts[i].categories[j].products[k].count">
                            <!--
                                <input type="number" [id]="i" [(ngModel)]="productsUpdated[i].count" 
                                    [readonly]="productsUpdated[i].edit" 
                                    [ngClass]="{'readonly': productsUpdated[i].edit}" 
                                >
                            -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!---->
        <div
            *ngIf="listOfProducts[0] && !listOfProducts[0].family_name"
        >
            <div 
                class="order_is_delivered-table-lines"
                *ngFor="let categorie of listOfProducts; index as i"
            >
                <div class="order_is_delivered-table-lines-categorie">
                    <div class="order_is_delivered-table-lines-categorie-details">
                        <div class="order_is_delivered-table-lines-categorie-details-img" 
                            [style.background-image]="'url(' + categorie.image_cat + ')'"
                            *ngIf="!categorie.showProducts"
                        >
                        </div>
                        <div>{{ categorie.name }}</div>
                    </div>
                    <div class="order_is_delivered-table-lines-categorie-actions">
                        <div class="order_is_delivered-table-lines-categorie-actions-count">
                            <span *ngIf="!(numberOfSelectedProduct[i] > 0)">0</span>
                            <span *ngIf="numberOfSelectedProduct[i] > 0">{{ numberOfSelectedProduct[i] }}</span>/{{ categorie.products.length }}
                        </div>
                        <mat-icon class="material-symbols-outlined" *ngIf="!categorie.showProducts" (click)="showMore(i)">expand_more</mat-icon>
                        <mat-icon class="material-symbols-outlined" *ngIf="categorie.showProducts"  (click)="showLess(i)">expand_less</mat-icon>
                    </div>
                </div>
                <div *ngIf="categorie.showProducts">
                    <div class="order_is_delivered-table-lines-products" 
                        *ngFor="let product of categorie.products; index as j" 
                    >
                        <div class="order_is_delivered-table-lines-product">
                            <div class="order_is_delivered-table-lines-product-img" [style.background-image]="'url(' + product.imageUrl + ')'"></div>
                            <span [innerHTML]="highlightText(product.designation)"></span>
                        </div>
                        <div 
                            class="order_is_delivered-table-lines-count"
                        >
                        <input type="number" (input)="numberOfSelectedProductsForEachCategory()" [(ngModel)]="listOfProducts[i].products[j].count">
                        <!--
                            <input type="number" [id]="i" [(ngModel)]="productsUpdated[i].count" 
                                [readonly]="productsUpdated[i].edit" 
                                [ngClass]="{'readonly': productsUpdated[i].edit}" 
                            >
                        -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  *ngIf="listOfProducts.length != 0" class="order_is_delivered-submit btn" (click)="addToBasket()">VALIDER</div>
</section>

<!--
            
-->
