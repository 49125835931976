import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-popup-accept-an-order',
  templateUrl: './popup-accept-an-order.component.html',
  styleUrls: ['./popup-accept-an-order.component.scss']
})
export class PopupAcceptAnOrderComponent implements OnInit {

  addProduct = false
  productName = ""
  newProductsForm = new FormGroup({
    productName : new FormControl(""),
  })
  listOfNewProducts = []
  newAddedProducts = []

  newProducts: Observable<string[]>;

  productsUpdated = []
  listOfProducts

  note = ""
  tokenApi = window.localStorage.getItem('token');
  caisseId = window.localStorage.getItem('caisse');

  pending: boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) public orderDetails: any,
                      private dialog: MatDialog, 
                      private http: HttpService,
                      private snackBar: MatSnackBar,
                      ) { }

  ngOnInit(): void {
    this.note = this.orderDetails.note
    this.http.getNewProductList(this.orderDetails.orderId).subscribe( listOfProducts => {
      this.listOfNewProducts = listOfProducts.products
    })
    this.listOfProducts = this.orderDetails.products
    for (let index = 0; index < this.listOfProducts.length; index++) {
        if( this.listOfProducts[index].sended == null && this.listOfProducts[index].received == null ){
      
          this.productsUpdated[index] = {
            "id": this.listOfProducts[index].id,
            "count":this.listOfProducts[index].count,
            "designation": this.listOfProducts[index].designation,
            "valid": false,
            "edit": true
          }
        }else if(this.listOfProducts[index].sended != null && this.listOfProducts[index].received == null
                ){
           
                  this.productsUpdated[index] = {
                    "id": this.listOfProducts[index].id,
                    "count":this.listOfProducts[index].sended,
                    "designation": this.listOfProducts[index].designation,
                    "valid": false,
                    "edit": true
                  }
        }
        else{
            this.productsUpdated[index] = {
            "id": this.listOfProducts[index].id,
            "count":this.listOfProducts[index].received,
            "designation": this.listOfProducts[index].designation,
            "valid": false,
            "edit": true
          }
        }      
    }

    this.newProducts = this.newProductsForm.get('productName').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );


  }

  delete(index){
    this.newAddedProducts.splice(index, 1);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.listOfNewProducts.filter(product => product.designation.toLowerCase().includes(filterValue));
  }

  addNewProduct(option){
    let newProduct = option
    option.count = 0
    this.newProductsForm.controls['productName'].setValue('')
    this.addProduct = false
    this.newAddedProducts.push(newProduct)
  }

  showNewButtonSearsh(){
    this.addProduct = !this.addProduct
  }

  validate(index){
    if(this.productsUpdated[index].count == null){
      this.productsUpdated[index].count = 0
    }
    this.productsUpdated[index].valid = false
    this.productsUpdated[index].edit = true
  }

  edit(index){
    this.productsUpdated[index].edit = false
    this.productsUpdated[index].valid = true
    this.productsUpdated.forEach( (product, i) => {
      if(index != i) {
        product.edit = true
        product.valid = false
      }
    });
    //document.getElementById(index).focus()
  }


  addQuantitySended(){
    let aProductThatMustBeQuantified = this.productsUpdated.filter( product => product.valid == true )
    let newProductWithNullCount = this.newAddedProducts.filter( product => product.count == null )
    let newProductWithZeroCount = this.newAddedProducts.filter( product => product.count == 0 )
    if(aProductThatMustBeQuantified.length != 0 ){
      this.snackBar.open("Veuillez valider la quantité du produit "+ aProductThatMustBeQuantified[0].designation +"!","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      })
    }else if(newProductWithNullCount.length != 0 ){
      this.snackBar.open("Veuillez valider la quantité du produit "+ newProductWithNullCount[0].designation +"!","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      })
    }else if(newProductWithZeroCount.length != 0 ){
      this.snackBar.open("Veuillez valider la quantité du produit "+ newProductWithZeroCount[0].designation +"!","Fermer", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 1500,
      })
    }else{  
      if(!this.pending){
        this.pending = true
        let orderId = this.orderDetails.orderId
        let products = JSON.stringify(this.productsUpdated)
        let newAddedProducts = JSON.stringify(this.newAddedProducts)
        
        this.http.acceptAnOrder(orderId, this.caisseId,products, newAddedProducts ,this.note).subscribe(result => {
          window.location.reload()
        })
        
      }
    }
  }






  
}