import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginButtonClicked = false
  hide = true;

  screenWidth;
  screenHeight;
  screenWidthInPixels;
  screenHeightInPixels;

  sectionLogin;

  loginForm = new FormGroup({
    username : new FormControl( null , Validators.required),
    password : new FormControl(null, [Validators.min(9) ,Validators.required])
  })

  constructor(
    private httpService: HttpService,
    private router: Router,
    private snackBar: MatSnackBar,
    private element: ElementRef,
    private authService: AuthService
  ) { 
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      this.screenWidthInPixels = window.innerWidth + 'px'
      this.screenHeightInPixels = window.innerHeight + 'px'
  }
  /*
  @HostListener('window:resize', ['$event'])
      onResize(event) {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
        this.screenWidthInPixels = window.innerWidth + 'px'
        this.screenHeightInPixels = window.innerHeight + 'px'
      }
  */

  ngOnInit(): void {
    
      if(this.authService.userConnected){
        this.router.navigate(['/dashboard'])
      }else if(!this.authService.userConnected){
        this.router.navigate(['/choose-user'])
      }

  }
  
  login(){
    this.loginButtonClicked = true
    this.httpService.login(this.loginForm.value.username,this.loginForm.value.password).subscribe(res => {
      
      setTimeout(()=>{
        if(res){
          window.localStorage.setItem('token', res.token_api);
          window.localStorage.setItem('caisse', res.id_caisse);
          window.localStorage.setItem('name', res.nom_boutique);
          this.authService.boxConnected = true
          this.sectionLogin = this.element.nativeElement.querySelector('.section_login')
          this.sectionLogin.classList.add('animate__animated')
          this.sectionLogin.classList.add('animate__slideOutLeft')
          this.sectionLogin.classList.add('duration')
          setTimeout(()=>{
            this.loginButtonClicked = false;
            this.router.navigate(['/choose-user'])
          },200)
          
        }else{
          this.loginButtonClicked = false;
          this.snackBar.open("Nom d'utilisateur ou mot de passe incorrect !","Fermer", {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
          });
        }
      },500)
    })
  }

}
